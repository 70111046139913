/* Base Color and Background Mixins */
body .alert-window {
  --alert-baseColor-confirm: var(--ui-color-default);
}

body #admin-app .alert-window,
body #app .alert-window {
  --alert-baseColor-confirm: var(--baseColor-accent);
}

.alert-window {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fontFamily-default);
  /* transition values */
  --x1: 0.260;
  --y1: 0.270;
  --x2: 0.380;
  --y2: 0.925;
  --enter: cubic-bezier(var(--x1), var(--y1), var(--x2), var(--y2));
  /* Reversed transition values */
  --x1-r: calc(1 - var(--x2));
  --y1-r: calc(1 - var(--y2));
  --x2-r: calc(1 - var(--x1));
  --y2-r: calc(1 - var(--y1));
  --exit: cubic-bezier(var(--x1-r), var(--y1-r), var(--x2-r), var(--y2-r));
  opacity: 0;
  transition: opacity 0.28s var(--enter);
}
.alert-window.active {
  opacity: 1;
  transition: opacity 0.28s var(--enter);
}
.alert-window.over-cart {
  z-index: 99999;
}
.alert-window button {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.alert-window.active .alert-modal {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 0.28s var(--enter), transform 0.28s var(--enter);
}

.alert-modal {
  background: var(--ui-background-default);
  border-radius: 5px;
  padding: var(--ui-padding-uiWindow);
  padding-top: calc(var(--ui-padding-uiWindow) * 2);
  width: 430px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
  position: relative;
  transform-origin: bottom center;
  transform: translateY(-15px);
  transition: opacity 0.28s var(--enter), transform 0.28s var(--enter);
}
.alert-modal:after {
  content: "";
  box-shadow: inset 0px 0px 0px 1px rgba(var(--baseColor-default-reverse-rgb), 0.06);
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  pointer-events: none;
  border-radius: 5px;
}
.alert-modal .form {
  width: 100%;
  padding-right: calc(var(--ui-padding-uiWindow) * 0.5);
}
.alert-modal .form select {
  width: 100%;
  font-size: 16px;
}
.alert-modal .message {
  flex: 1;
  font-size: 16px;
}
.alert-modal .message .header {
  display: inline-block;
  margin-bottom: 1em;
  width: 100%;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  font-size: var(--fontSize-header);
  line-height: 1;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 600;
}
.alert-modal .message .alert-action-link {
  border-bottom: 1px solid rgba(var(--baseColor-default-reverse-rgb), 0.85);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  cursor: pointer;
  display: inline-block;
}
.alert-modal .message .alert-action-link .underlined, .alert-modal .message .alert-action-link:active .underlined {
  text-decoration: underline;
  border-bottom: none;
}
.alert-modal .message .alert-action-link:active {
  opacity: var(--opacity-downstate-default);
}
.alert-modal .message .sub-message {
  display: inline-block;
  width: 100%;
  margin-bottom: 1em;
  font-weight: normal;
  line-height: 1.3;
  text-align: left;
  color: var(--ui-color-flat-reverse);
}
.alert-modal .opt-buttons {
  padding: calc(var(--ui-padding-uiWindow) * 2) 0 0;
  display: flex;
  align-self: center;
}
.alert-modal .input {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  margin-top: -6px;
}
.alert-modal .input input[type=text] {
  height: auto;
  min-height: 32px;
  line-height: var(--ui-lineHeight-default);
  padding-top: calc(var(--ui-padding-default-vertical) - 4px);
  padding-bottom: calc(var(--ui-padding-default-vertical) - 3px);
  padding-left: calc(var(--ui-padding-default-horizontal) - 2px);
  padding-right: var(--ui-padding-default-horizontal);
  margin: 0 1px;
  outline: 1px solid rgba(var(--baseColor-accent-rgb), 0.4);
  font-family: var(--fontFamily-default);
  font-size: var(--fontSize-default);
  color: var(--ui-color-default);
  background: var(--ui-background-default);
  border: none;
  width: 84%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.alert-modal .buttons {
  display: flex;
  align-self: center;
}
.alert-modal .buttons button[button-style=rounded-2] {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  font-size: var(--fontSize-default);
  font-family: var(--fontFamily-default);
  padding: 0;
  text-align: left;
  white-space: nowrap;
  background: transparent;
  display: flex;
  margin: 0;
  justify-content: flex-start;
  width: 110px;
  flex: 1;
  padding: 8px;
  height: auto;
  line-height: 1;
  border-radius: var(--ui-button-border-radius);
  font-weight: 500;
  padding: 6.5px var(--ui-padding-default-horizontal) 7.5px var(--ui-padding-default-horizontal);
}
.alert-modal .buttons button[button-style=rounded-2].deny {
  background: rgba(var(--baseColor-default-reverse-rgb), 0.1);
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
}
.alert-modal .buttons button[button-style=rounded-2].confirm, .alert-modal .buttons button[button-style=rounded-2].notice {
  background: var(--alert-baseColor-confirm);
  color: rgba(var(--baseColor-accent-reverse-rgb), 1);
  fill: rgba(var(--baseColor-accent-reverse-rgb), 1);
}
.alert-modal .buttons button.confirm {
  position: relative;
}
.alert-modal .buttons button[button-style=rounded-2] + button[button-style=rounded-2] {
  margin-left: 5px;
}
.alert-modal .buttons button:active {
  opacity: var(--opacity-downstate-default);
  color: inherit;
}
.alert-modal .buttons + .footer {
  margin-top: 15px;
}
.alert-modal .footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.alert-modal .footer .checkbox {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  display: inline-block;
  color: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  fill: rgba(var(--baseColor-default-reverse-rgb), 0.6);
  padding-left: 26px;
  transform: translate(-5px, 2px);
}
.alert-modal .footer .checkbox input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.alert-modal .footer .checkbox input:after {
  width: var(--diameter-checkbox-circle);
  height: var(--diameter-checkbox-circle);
  content: "";
  cursor: pointer;
  pointer-events: none;
  position: absolute;
  visibility: visible;
  opacity: 0.8;
  /* Positioning is centered based on row height and indicator diameter */
  top: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.4' d='M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H12C13.3807 0.5 14.5 1.61929 14.5 3V12C14.5 13.3807 13.3807 14.5 12 14.5H3C1.61929 14.5 0.5 13.3807 0.5 12V3Z' stroke='black'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 80% 65%;
}
.alert-modal .footer .checkbox input:checked:after {
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 0C1.34314 0 0 1.34314 0 3V12C0 13.6569 1.34314 15 3 15H12C13.6569 15 15 13.6569 15 12V3C15 1.34314 13.6569 0 12 0H3ZM7.44257 11.5463C7.25537 11.8398 6.97424 12 6.63025 12C6.4632 12 6.30945 11.9622 6.16705 11.8799C6.02838 11.7999 5.91418 11.6855 5.81238 11.5527L5.81158 11.5516L3.22034 8.13782C3.08759 7.96338 3 7.75897 3 7.54858C3 7.07635 3.3504 6.73004 3.81696 6.73004C3.96063 6.73004 4.09656 6.75952 4.22406 6.83325C4.34766 6.90479 4.44525 7.00745 4.53082 7.12439L6.58521 9.93481L10.7323 3.18188C10.8124 3.05255 10.9067 2.93567 11.0362 2.85663C11.1699 2.7749 11.3127 2.75 11.4584 2.75C11.6793 2.75 11.8831 2.82544 12.0316 2.97742C12.1793 3.12866 12.25 3.33276 12.25 3.54932C12.25 3.74487 12.1921 3.92975 12.0621 4.13202L7.44257 11.5463Z' fill='%23FF0000'/%3E%3C/svg%3E%0A");
}
.alert-modal.publish, .alert-modal.dialog, .alert-modal.confirm, .alert-modal.notice, .alert-modal.slide-confirm {
  border-radius: var(--modalRadiusLarge-inner);
  min-height: 210px;
  padding: calc(var(--ui-padding-uiWindow) * 1.5) var(--ui-padding-uiWindow) var(--ui-padding-uiWindow) calc(var(--ui-padding-uiWindow) * 1.5);
}
.alert-modal.publish:after, .alert-modal.dialog:after, .alert-modal.confirm:after, .alert-modal.notice:after, .alert-modal.slide-confirm:after {
  border-radius: var(--modalRadiusLarge);
}
.alert-modal.publish .message, .alert-modal.dialog .message, .alert-modal.confirm .message, .alert-modal.notice .message, .alert-modal.slide-confirm .message {
  flex: 0;
  line-height: 0;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.alert-modal.publish .message .header, .alert-modal.dialog .message .header, .alert-modal.confirm .message .header, .alert-modal.notice .message .header, .alert-modal.slide-confirm .message .header {
  line-height: 1.3;
  margin-bottom: 0.2em;
  text-align: left;
}
.alert-modal.publish .message select, .alert-modal.dialog .message select, .alert-modal.confirm .message select, .alert-modal.notice .message select, .alert-modal.slide-confirm .message select {
  width: 100%;
}
.alert-modal.publish .buttons, .alert-modal.dialog .buttons, .alert-modal.confirm .buttons, .alert-modal.notice .buttons, .alert-modal.slide-confirm .buttons {
  display: flex;
  align-self: center;
  flex: 0 1;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: auto;
}
.alert-modal.publish .buttons button, .alert-modal.dialog .buttons button, .alert-modal.confirm .buttons button, .alert-modal.notice .buttons button, .alert-modal.slide-confirm .buttons button {
  flex: 0 1 auto;
  min-width: 110px;
  width: auto;
}
.alert-modal.publish .buttons button[button-style=rounded-2], .alert-modal.dialog .buttons button[button-style=rounded-2], .alert-modal.confirm .buttons button[button-style=rounded-2], .alert-modal.notice .buttons button[button-style=rounded-2], .alert-modal.slide-confirm .buttons button[button-style=rounded-2] {
  height: auto;
  line-height: 1;
  border-radius: var(--ui-button-border-radius) !important;
  font-weight: 500;
  padding: 6.5px var(--ui-padding-default-horizontal) 7.5px var(--ui-padding-default-horizontal);
}
.alert-modal.slide-confirm {
  padding: 0;
}
.alert-modal.slide-confirm .message {
  padding: 22px 20px;
  padding-bottom: 10px;
  flex: 1 1;
  font-size: 17px;
  line-height: 1.3em;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.alert-modal.slide-confirm .message .header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1em;
  font-weight: 700;
  line-height: 1.3;
}
.alert-modal.slide-confirm .buttons {
  padding: 0 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}
.alert-modal.slide-confirm .buttons .deny {
  color: rgba(var(--baseColor-default-reverse-rgb), 0.85);
  margin-bottom: 20px;
  font-size: 17px;
  line-height: 1.2;
  border-bottom: 1px solid rgba(var(--baseColor-default-reverse-rgb), 0.85);
  padding: 0;
  border-radius: 0;
  background: transparent;
  min-width: auto;
  width: auto;
  flex: 0;
}
.alert-modal.slide-confirm .buttons .deny:active {
  color: inherit;
  opacity: 0.6;
}
.alert-modal.publish .buttons {
  flex: 1;
}
.alert-modal.dialog .message .header {
  margin-bottom: 0;
}
.alert-modal.dialog .input {
  padding-right: calc(var(--ui-padding-uiWindow) * 1.5 - var(--ui-padding-uiWindow));
}
.alert-modal.dialog.safari .input input[type=text] {
  padding-bottom: calc(var(--ui-padding-default-vertical) - 2px);
}

.dark .alert-modal:after {
  box-shadow: inset 0px 0px 0px 1px var(--ui-window-border-background);
  border-radius: var(--modalRadiusLarge-inner);
  inset: 0px;
}
.dark .alert-modal .footer .checkbox input:after {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.47' d='M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H12C13.3807 0.5 14.5 1.61929 14.5 3V12C14.5 13.3807 13.3807 14.5 12 14.5H3C1.61929 14.5 0.5 13.3807 0.5 12V3Z' stroke='rgba(255, 255, 255, .85)'/%3E%3C/svg%3E%0A");
}
.dark .alert-modal .footer .checkbox input:checked:after {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 0C1.34314 0 0 1.34314 0 3V12C0 13.6569 1.34314 15 3 15H12C13.6569 15 15 13.6569 15 12V3C15 1.34314 13.6569 0 12 0H3ZM7.44257 11.5463C7.25537 11.8398 6.97424 12 6.63025 12C6.4632 12 6.30945 11.9622 6.16705 11.8799C6.02838 11.7999 5.91418 11.6855 5.81238 11.5527L5.81158 11.5516L3.22034 8.13782C3.08759 7.96338 3 7.75897 3 7.54858C3 7.07635 3.3504 6.73004 3.81696 6.73004C3.96063 6.73004 4.09656 6.75952 4.22406 6.83325C4.34766 6.90479 4.44525 7.00745 4.53082 7.12439L6.58521 9.93481L10.7323 3.18188C10.8124 3.05255 10.9067 2.93567 11.0362 2.85663C11.1699 2.7749 11.3127 2.75 11.4584 2.75C11.6793 2.75 11.8831 2.82544 12.0316 2.97742C12.1793 3.12866 12.25 3.33276 12.25 3.54932C12.25 3.74487 12.1921 3.92975 12.0621 4.13202L7.44257 11.5463Z' fill='%23027AFF'/%3E%3C/svg%3E%0A");
}

.mobile .alert-modal {
  max-width: var(--ui-maxWidth-mobile);
}